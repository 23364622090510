/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
	
@import '~animate.css/animate.min.css';
 
.cart-modal {
  --height: 70%;
  --border-radius: 10px;
  padding: 25px;
}

.order-detail-modal {
    --height: 70%;
    --border-radius: 10px;
    padding: 20px;
  }

.box {
  height: 50px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.box p {
  line-height: 50px;
  font-size: 1em;
  color: white;
  text-align: center;
  font-weight: bold;
}

#g1 {
    --background: linear-gradient(90deg, #69b7eb, #b3dbd3, #f4d6db);
    margin-bottom: 20px;
}
  
/* Gradient 2 */
#g2 {
    --background: linear-gradient(90deg, #cfecd0, #ffc5ca);
    margin-bottom: 20px;
}

/* Gradient 3 */
#g3 {
    --background: linear-gradient(90deg, #f598a8, #f6edb2);
    margin-bottom: 20px;
}

/* Gradient 4 */
#g4 {
    --background: linear-gradient(90deg, #ee5c87, #f1a4b5, #d587b3);
    margin-bottom: 20px;
}

/* Gradient 5 */
#g5 {
    --background: linear-gradient(90deg, #b9deed, #efefef);
    margin-bottom: 20px;
}

/* Gradient 6 */
#g6 {
    --background: linear-gradient(90deg, #aea4e3, #d3ffe8);
    margin-bottom: 20px;
}

/* Gradient 7 */
#g7 {
    --background: linear-gradient(90deg, #faf0cd, #fab397);
    margin-bottom: 20px;
}

/* Gradient 8 */
#g8 {
    --background: linear-gradient(90deg, #cfecd0, #a0cea7, #9ec0db);
    margin-bottom: 20px;
}

.ios_pad{
    padding-top: 40px !important;
}
.ios_padd{
    --padding-top: 40px !important;
}

.loader-css-class {
    --background:#261bff;
    --spinner-color:#ffffff;
 }

 .show_alert_error{
    .alert-head{
        background-color:#f598a8 !important;
        text-align: center;
    }
    // .alert-message{
    // }

}



.show_alert_success{
    .alert-head{
        background-color:#abee91 !important;
        text-align: center;
    }

    // .alert-message{
    // }
}

.alert-message{
    padding-top: 20px !important;
    color:#000 !important;
}
